<template>
  <div>
    <div class="content-body" id="dashboard-ecommerce">
      <section>
        <div class="row match-height">
          <div class="col-xl-12 col-md-12 col-12">
            <div class="card-header px-0">
              <div class="col-xl-12 col-md-12 col-12 d-flex">
                <div class="col-6 align-items-end d-flex">
                  <h4 class="d-flex">{{ showDate }}</h4>
                </div>
                <div class="col-6">
                  <button
                    class="btn btn-primary btn-next week_button float-end"
                  >
                    <vue-feather
                      type="chevron-left"
                      class="align-middle ms-sm-25 ms-0 pre"
                      @click="pre"
                    ></vue-feather>
                    <span class="align-middle d-sm-inline-block week"
                      >Week</span
                    >
                    <vue-feather
                      type="chevron-right"
                      class="align-middle ms-sm-25 ms-0 next"
                      @click="next"
                    ></vue-feather>
                  </button>
                </div>
              </div>
            </div>
            <div class="card card-congratulation-medal">
              <div class="card-body table_scoll">
                <div class="row mb-1">
                  <div
                    class="demo-inline-spacing text-center justify-content-center"
                    v-if="loader"
                  >
                    <div class="spinner-grow text-primary me-1" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                  <div class="main_row col-12 d-flex" v-else>
                    <div
                      class="week_div"
                      v-for="(week, index) in list"
                      :key="week"
                    >
                      <div class="card week-card">
                        <div class="card-header week-card-header">
                          <span>{{
                            dateName(index) + " " + dayName(index)
                          }}</span>
                        </div>
                        <!-- eslint-disable -->
                        <div
                          class="card-body week-card-body"
                          v-for="(item, ind) in list[index]"
                          :key="ind"
                          v-if="list[index] && list[index].length > 0"
                        >
                          <div :class="!item.type ? 'villa' : ''" :id="item.id">
                            <h4 class="card-title text-left card-title-week">
                              {{ item.booking_id }}
                            </h4>
                            <div class="card-text">
                              <div class="card-person-div text-title">
                                {{ fullname(item?.villa_detail) }}
                              </div>
                              <div
                                class="card-person-div d-flex justify-content-between align-items-center"
                              >
                                <span>{{ item?.villa_detail?.mobile }}</span>
                                <span class>
                                  <vue-feather
                                    type="check-circle"
                                    class="checked success"
                                    v-if="item.user.user_type == 'Owner'"
                                  ></vue-feather>
                                  <!-- <vue-feather
                                    type="check-circle"
                                    class="checked"
                                    :class="{
                                      success:
                                        parseInt(item.villa_qty) ==
                                          parseInt(item.villa_pending_qty) &&
                                        item.user.user_type == 'Guest',
                                      pending:
                                        item.villa_qty !=
                                          item.villa_pending_qty &&
                                        item.user.user_type == 'Guest',
                                    }"
                                    v-else
                                  ></vue-feather>  -->
                                </span>
                              </div>
                              <div
                                class="card-person-div"
                                v-if="item.villa_detail && item.villa_detail.villa_type_detail"
                              >
                                <div
                                  class="list_card"
                                  v-if="item.villa_detail.villa_type_detail.length != 0"
                                  v-for="(list, list_index) in item.villa_detail
                                    .villa_type_detail"
                                  :key="list_index"
                                >
                                  <div class="list_header" :style="{background:villaColor(list_index).label_background}">
                                    {{ villaColor(list_index).text }}
                                  </div>
                                  <ul
                                    class="villa_number_ul"
                                    v-if="list.length"
                                  >
                                    <li
                                      v-for="(villa, villa_index) in list"
                                      :key="villa_index"
                                    >
                                      {{ villa }}
                                    </li>
                                  </ul>
                                  <ul
                                    class="villa_number_ul data_not_avilable"
                                    v-else
                                  >
                                    <li>Not Assigned Yet!</li>
                                  </ul>
                                </div>
                                <div v-else
                                  class="list_card">
                                  <ul
                                    class="villa_number_ul data_not_avilable"
                                  >
                                    <li>Not Assigned Yet!</li>
                                  </ul>
                                </div>
                              </div>
                              <div class="card-person-div">
                                <router-link
                                  :to="{
                                    name: 'villa-assign',
                                    params: {
                                      booking_id: item.id,
                                    },
                                  }"
                                  class="btn btn-sm btn-gradient-primary w-100"
                                  >{{
                                    item.villa_qty > item.villa_pending_qty
                                      ? "Assign"
                                      : "Assign"
                                  }}
                                </router-link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- eslint-enable -->
                        <div class="card-body week-card-body" v-else>
                          <div class="nodata">
                            <span>No Booking </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="row match-height">
          <div class="col-xl-12 col-md-12 col-12">
            <div class="card-header px-0">
              <div class="col-xl-12 col-md-12 col-12 d-flex">
                <div class="col-4 align-items-end d-flex">
                  <h4 class="d-flex">Picnic (Incoming Today)</h4>
                </div>
                <div class="col-4"></div>
                <div class="col-4"></div>
              </div>
            </div>
            <div class="card card-congratulation-medal 2">
              <div class="card-body">
                <div class="row mb-1">
                  <div
                    class="demo-inline-spacing text-center justify-content-center"
                    v-if="loader_picnic"
                  >
                    <div class="spinner-grow text-primary me-1" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                  <div class="main_row col-12 d-flex table_scoll" v-else>
                    <div class="card-datatable w-100">
                      <table class="dt-column-search table table-responsive">
                        <thead>
                          <tr>
                            <template
                              v-for="head in today_picnic_header"
                              :key="head"
                            >
                              <th>{{ head }}</th>
                            </template>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-show="today_picnic_list.length > 0"
                            v-for="(li, index) in today_picnic_list"
                            :key="index"
                          >
                            <td>{{ li.booking_id }}</td>
                            <td>{{ li.check_in_date }}</td>
                            <td>{{ li.check_out_date }}</td>
                            <td class="text-capitalize">{{ li.title }}</td>
                            <td class="text-capitalize">
                              {{
                                li.persons && li.persons[0].name
                                  ? li.persons[0].name
                                  : ""
                              }}
                            </td>
                            <td>
                              {{
                                li.persons && li.persons[0].mobile
                                  ? li.persons[0].mobile
                                  : ""
                              }}
                            </td>
                            <td>
                              <button
                                class="btn btn-sm btn-gradient-primary float-end"
                                data-bs-toggle="modal"
                                :data-bs-target="'#BookingDetailModel' + index"
                              >
                                <vue-feather type="users"></vue-feather>
                              </button>
                              <div
                                class="modal fade"
                                :id="'BookingDetailModel' + index"
                                tabindex="-1"
                                aria-labelledby="exampleModalCenterTitle"
                                aria-hidden="true"
                              >
                                <div class="modal-dialog modal-dialog-centered">
                                  <div class="modal-content">
                                    <div class="modal-header">
                                      <h5 class="modal-title">
                                        <b>{{ li.title }}</b>
                                      </h5>
                                      <button
                                        type="button"
                                        class="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                      ></button>
                                    </div>
                                    <div class="modal-body">
                                      <div class="row detail_card_wrapper">
                                        <div class="detail_card mb">
                                          <div class="check_in_out_box">
                                            <div class="check_in_box">
                                              <div class="check_in_title">
                                                <span>Check-in</span>
                                              </div>
                                              <div class="check_in_text">
                                                <span>{{
                                                  li.check_in_date
                                                }}</span>
                                              </div>
                                            </div>
                                            <div class="check_in_box">
                                              <div class="check_in_title">
                                                <span>Check-Out</span>
                                              </div>
                                              <div class="check_in_text">
                                                <span>{{
                                                  li.check_out_date
                                                }}</span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="detail_card mb">
                                          <div class="check_in_out_box">
                                            <div class="check_in_box">
                                              <div class="check_in_title">
                                                <span>Adults</span>
                                              </div>
                                              <div class="check_in_text">
                                                <span>{{
                                                  li.total_adult
                                                }}</span>
                                              </div>
                                            </div>
                                            <div class="check_in_box">
                                              <div class="check_in_title">
                                                <span>Childs</span>
                                              </div>
                                              <div class="check_in_text">
                                                <span>{{
                                                  li.total_child
                                                }}</span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          class="detail_card mb"
                                          v-if="li.persons && li.persons[0]"
                                        >
                                          <div class="check_in_out_box">
                                            <div class="check_in_text member">
                                              <span class="heading"
                                                >Primary Member</span
                                              >
                                              <ul class="list">
                                                <li>
                                                  <span class="icon">
                                                    <vue-feather
                                                      type="users"
                                                    ></vue-feather>
                                                  </span>
                                                  <span class="text">{{
                                                    li.persons[0].name
                                                  }}</span>
                                                </li>
                                                <li>
                                                  <span class="icon">
                                                    <vue-feather
                                                      type="phone-call"
                                                    ></vue-feather>
                                                  </span>
                                                  <span class="text">{{
                                                    li.persons[0].mobile
                                                  }}</span>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </div>

                                        <span
                                          class="heading_other"
                                          v-if="
                                            Array.isArray(li.persons) &&
                                            li.persons.length > 1
                                          "
                                          >Other Members</span
                                        >
                                        <div
                                          class="remove_padding"
                                          v-for="item in li.persons"
                                          :key="item.id"
                                        >
                                          <div
                                            class="detail_card invert"
                                            v-if="!item.is_primary"
                                          >
                                            <div class="check_in_out_box">
                                              <div class="check_in_text member">
                                                <ul class="list">
                                                  <li>
                                                    <span class="icon">
                                                      <vue-feather
                                                        type="users"
                                                      ></vue-feather>
                                                    </span>
                                                    <span class="text">{{
                                                      item.name
                                                    }}</span>
                                                  </li>
                                                  <li>
                                                    <span class="icon">
                                                      <vue-feather
                                                        type="phone-call"
                                                      ></vue-feather>
                                                    </span>
                                                    <span class="text">{{
                                                      item.mobile
                                                    }}</span>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr v-show="today_picnic_list.length === 0">
                            <td colspan="16" class="text-center">
                              No Data Found
                            </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <template
                              v-for="head in today_picnic_header"
                              :key="head"
                            >
                              <th>{{ head }}</th>
                            </template>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="row match-height">
          <div class="col-xl-3 col-md-3 col-sm-6 col-3">
            <div class="card-header px-0">
              <div class="col-12 align-items-end d-flex">
                <h4 class="d-flex">Booking (Today)</h4>
              </div>
            </div>
            <div class="card card-congratulation-medal">
              <router-link to="/booking">
                <div class="card-body pb-50" style="position: relative">
                  <h2 class="font-weight-bolder mb-1">{{ today_bookings }}</h2>
                  <div>
                    <div
                      id="apexchartsoheokxio"
                      class="apexcharts-canvas apexchartsoheokxio apexcharts-theme-light"
                    >
                      <svg
                        id="SvgjsSvg2688"
                        width="100%"
                        height="80"
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xmlns:svgjs="http://svgjs.com/svgjs"
                        class="apexcharts-svg"
                        xmlns:data="ApexChartsNS"
                        transform="translate(0, 0)"
                        style="
                          background: transparent;
                          --darkreader-inline-bgimage: initial;
                          --darkreader-inline-bgcolor: transparent;
                        "
                        data-darkreader-inline-bgimage=""
                        data-darkreader-inline-bgcolor=""
                      >
                        <g
                          id="SvgjsG2690"
                          class="apexcharts-inner apexcharts-graphical"
                          transform="translate(0, 0)"
                        >
                          <defs id="SvgjsDefs2689">
                            <clipPath id="gridRectMask2oxev4no">
                              <rect
                                id="SvgjsRect2695"
                                width="100%"
                                height="82"
                                x="-3"
                                y="-1"
                                rx="0"
                                ry="0"
                                opacity="1"
                                stroke-width="0"
                                stroke="none"
                                stroke-dasharray="0"
                                fill="#fff"
                                data-darkreader-inline-fill=""
                                data-darkreader-inline-stroke=""
                                style="
                                  --darkreader-inline-fill: #131314;
                                  --darkreader-inline-stroke: none;
                                "
                              ></rect>
                            </clipPath>
                            <clipPath id="gridRectMarkerMask2oxev4no">
                              <rect
                                id="SvgjsRect2696"
                                width="100%"
                                height="84"
                                x="-2"
                                y="-2"
                                rx="0"
                                ry="0"
                                opacity="1"
                                stroke-width="0"
                                stroke="none"
                                stroke-dasharray="0"
                                fill="#fff"
                                data-darkreader-inline-fill=""
                                data-darkreader-inline-stroke=""
                                style="
                                  --darkreader-inline-fill: #131314;
                                  --darkreader-inline-stroke: none;
                                "
                              ></rect>
                            </clipPath>
                          </defs>
                          <line
                            id="SvgjsLine2694"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="80"
                            stroke="#b6b6b6"
                            stroke-dasharray="3"
                            class="apexcharts-xcrosshairs"
                            x="0"
                            y="0"
                            width="1"
                            height="80"
                            fill="#b1b9c4"
                            filter="none"
                            fill-opacity="0.9"
                            stroke-width="1"
                            data-darkreader-inline-fill=""
                            data-darkreader-inline-stroke=""
                            style="
                              --darkreader-inline-fill: #2d3035;
                              --darkreader-inline-stroke: #353738;
                            "
                          ></line>
                          <g
                            id="SvgjsG2706"
                            class="apexcharts-xaxis"
                            transform="translate(0, 0)"
                          >
                            <g
                              id="SvgjsG2707"
                              class="apexcharts-xaxis-texts-g"
                              transform="translate(0, -4)"
                            ></g>
                          </g>
                          <g id="SvgjsG2720" class="apexcharts-grid">
                            <g
                              id="SvgjsG2721"
                              class="apexcharts-gridlines-horizontal"
                              style="display: none"
                            >
                              <line
                                id="SvgjsLine2723"
                                x1="0"
                                y1="0"
                                x2="265.547"
                                y2="0"
                                stroke="#e0e0e0"
                                stroke-dasharray="0"
                                class="apexcharts-gridline"
                                data-darkreader-inline-stroke=""
                                style="--darkreader-inline-stroke: #2c2e2f"
                              ></line>
                              <line
                                id="SvgjsLine2724"
                                x1="0"
                                y1="20"
                                x2="265.547"
                                y2="20"
                                stroke="#e0e0e0"
                                stroke-dasharray="0"
                                class="apexcharts-gridline"
                                data-darkreader-inline-stroke=""
                                style="--darkreader-inline-stroke: #2c2e2f"
                              ></line>
                              <line
                                id="SvgjsLine2725"
                                x1="0"
                                y1="40"
                                x2="265.547"
                                y2="40"
                                stroke="#e0e0e0"
                                stroke-dasharray="0"
                                class="apexcharts-gridline"
                                data-darkreader-inline-stroke=""
                                style="--darkreader-inline-stroke: #2c2e2f"
                              ></line>
                              <line
                                id="SvgjsLine2726"
                                x1="0"
                                y1="60"
                                x2="265.547"
                                y2="60"
                                stroke="#e0e0e0"
                                stroke-dasharray="0"
                                class="apexcharts-gridline"
                                data-darkreader-inline-stroke=""
                                style="--darkreader-inline-stroke: #2c2e2f"
                              ></line>
                              <line
                                id="SvgjsLine2727"
                                x1="0"
                                y1="80"
                                x2="265.547"
                                y2="80"
                                stroke="#e0e0e0"
                                stroke-dasharray="0"
                                class="apexcharts-gridline"
                                data-darkreader-inline-stroke=""
                                style="--darkreader-inline-stroke: #2c2e2f"
                              ></line>
                            </g>
                            <g
                              id="SvgjsG2722"
                              class="apexcharts-gridlines-vertical"
                              style="display: none"
                            ></g>
                            <line
                              id="SvgjsLine2729"
                              x1="0"
                              y1="80"
                              x2="265.547"
                              y2="80"
                              stroke="transparent"
                              stroke-dasharray="0"
                              data-darkreader-inline-stroke=""
                              style="--darkreader-inline-stroke: transparent"
                            ></line>
                            <line
                              id="SvgjsLine2728"
                              x1="0"
                              y1="1"
                              x2="0"
                              y2="80"
                              stroke="transparent"
                              stroke-dasharray="0"
                              data-darkreader-inline-stroke=""
                              style="--darkreader-inline-stroke: transparent"
                            ></line>
                          </g>
                          <g
                            id="SvgjsG2697"
                            class="apexcharts-line-series apexcharts-plot-series"
                          >
                            <g
                              id="SvgjsG2698"
                              class="apexcharts-series"
                              seriesName="seriesx1"
                              data:longestSeries="true"
                              rel="1"
                              data:realIndex="0"
                            >
                              <path
                                id="SvgjsPath2701"
                                d="M 0 19C 9.294145 19 17.260555 32 26.5547 32C 35.848845 32 43.815255 11 53.1094 11C 62.403545 11 70.369955 28 79.6641 28C 88.958245 28 96.924655 20 106.2188 20C 115.512945 20 123.47935500000001 40 132.7735 40C 142.067645 40 150.03405500000002 1 159.3282 1C 168.622345 1 176.58875500000002 20 185.8829 20C 195.177045 20 203.14345500000002 21 212.4376 21C 221.731745 21 229.698155 37 238.9923 37C 248.28644500000001 37 256.252855 18 265.547 18"
                                fill="none"
                                fill-opacity="1"
                                stroke="rgba(125,167,65,1)"
                                stroke-opacity="1"
                                stroke-linecap="butt"
                                stroke-width="2"
                                stroke-dasharray="0"
                                class="apexcharts-line"
                                index="0"
                                clip-path="url(#gridRectMask2oxev4no)"
                                pathTo="M 0 19C 9.294145 19 17.260555 32 26.5547 32C 35.848845 32 43.815255 11 53.1094 11C 62.403545 11 70.369955 28 79.6641 28C 88.958245 28 96.924655 20 106.2188 20C 115.512945 20 123.47935500000001 40 132.7735 40C 142.067645 40 150.03405500000002 1 159.3282 1C 168.622345 1 176.58875500000002 20 185.8829 20C 195.177045 20 203.14345500000002 21 212.4376 21C 221.731745 21 229.698155 37 238.9923 37C 248.28644500000001 37 256.252855 18 265.547 18"
                                pathFrom="M -1 80L -1 80L 26.5547 80L 53.1094 80L 79.6641 80L 106.2188 80L 132.7735 80L 159.3282 80L 185.8829 80L 212.4376 80L 238.9923 80L 265.547 80"
                                data-darkreader-inline-stroke=""
                                style="
                                  --darkreader-inline-stroke: rgba(
                                    86,
                                    81,
                                    138,
                                    0.85
                                  );
                                "
                              ></path>
                              <g
                                id="SvgjsG2699"
                                class="apexcharts-series-markers-wrap"
                                data:realIndex="0"
                              ></g>
                            </g>
                            <g
                              id="SvgjsG2702"
                              class="apexcharts-series"
                              seriesName="seriesx2"
                              data:longestSeries="true"
                              rel="2"
                              data:realIndex="1"
                            >
                              <path
                                id="SvgjsPath2705"
                                d="M 0 60C 9.294145 60 17.260555 70 26.5547 70C 35.848845 70 43.815255 50 53.1094 50C 62.403545 50 70.369955 65 79.6641 65C 88.958245 65 96.924655 57 106.2188 57C 115.512945 57 123.47935500000001 80 132.7735 80C 142.067645 80 150.03405500000002 55 159.3282 55C 168.622345 55 176.58875500000002 65 185.8829 65C 195.177045 65 203.14345500000002 60 212.4376 60C 221.731745 60 229.698155 75 238.9923 75C 248.28644500000001 75 256.252855 53 265.547 53"
                                fill="none"
                                fill-opacity="1"
                                stroke="rgba(220,218,227,0.85)"
                                stroke-opacity="1"
                                stroke-linecap="butt"
                                stroke-width="1"
                                stroke-dasharray="5"
                                class="apexcharts-line"
                                index="1"
                                clip-path="url(#gridRectMask2oxev4no)"
                                pathTo="M 0 60C 9.294145 60 17.260555 70 26.5547 70C 35.848845 70 43.815255 50 53.1094 50C 62.403545 50 70.369955 65 79.6641 65C 88.958245 65 96.924655 57 106.2188 57C 115.512945 57 123.47935500000001 80 132.7735 80C 142.067645 80 150.03405500000002 55 159.3282 55C 168.622345 55 176.58875500000002 65 185.8829 65C 195.177045 65 203.14345500000002 60 212.4376 60C 221.731745 60 229.698155 75 238.9923 75C 248.28644500000001 75 256.252855 53 265.547 53"
                                pathFrom="M -1 80L -1 80L 26.5547 80L 53.1094 80L 79.6641 80L 106.2188 80L 132.7735 80L 159.3282 80L 185.8829 80L 212.4376 80L 238.9923 80L 265.547 80"
                                data-darkreader-inline-stroke=""
                                style="
                                  --darkreader-inline-stroke: rgba(
                                    157,
                                    156,
                                    154,
                                    0.85
                                  );
                                "
                              ></path>
                              <g
                                id="SvgjsG2703"
                                class="apexcharts-series-markers-wrap"
                                data:realIndex="1"
                              ></g>
                            </g>
                            <g
                              id="SvgjsG2700"
                              class="apexcharts-datalabels"
                              data:realIndex="0"
                            ></g>
                            <g
                              id="SvgjsG2704"
                              class="apexcharts-datalabels"
                              data:realIndex="1"
                            ></g>
                          </g>
                          <line
                            id="SvgjsLine2730"
                            x1="0"
                            y1="0"
                            x2="265.547"
                            y2="0"
                            stroke="#b6b6b6"
                            stroke-dasharray="0"
                            stroke-width="1"
                            class="apexcharts-ycrosshairs"
                            data-darkreader-inline-stroke=""
                            style="--darkreader-inline-stroke: #353738"
                          ></line>
                          <line
                            id="SvgjsLine2731"
                            x1="0"
                            y1="0"
                            x2="265.547"
                            y2="0"
                            stroke-dasharray="0"
                            stroke-width="0"
                            class="apexcharts-ycrosshairs-hidden"
                          ></line>
                          <g
                            id="SvgjsG2732"
                            class="apexcharts-yaxis-annotations"
                          ></g>
                          <g
                            id="SvgjsG2733"
                            class="apexcharts-xaxis-annotations"
                          ></g>
                          <g
                            id="SvgjsG2734"
                            class="apexcharts-point-annotations"
                          ></g>
                        </g>
                        <rect
                          id="SvgjsRect2693"
                          width="0"
                          height="0"
                          x="0"
                          y="0"
                          rx="0"
                          ry="0"
                          opacity="1"
                          stroke-width="0"
                          stroke="none"
                          stroke-dasharray="0"
                          fill="#fefefe"
                          data-darkreader-inline-fill=""
                          data-darkreader-inline-stroke=""
                          style="
                            --darkreader-inline-fill: #adacab;
                            --darkreader-inline-stroke: none;
                          "
                        ></rect>
                        <g
                          id="SvgjsG2719"
                          class="apexcharts-yaxis"
                          rel="0"
                          transform="translate(-18, 0)"
                        ></g>
                        <g id="SvgjsG2691" class="apexcharts-annotations"></g>
                      </svg>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
          <div class="col-xl-3 col-md-3 col-sm-6 col-3">
            <div class="card-header px-0">
              <div class="col-12 align-items-end d-flex">
                <h4 class="d-flex">Users (Today)</h4>
              </div>
            </div>
            <div class="card card-congratulation-medal">
              <router-link to="/user">
                <div class="card-body pb-50" style="position: relative">
                  <h2 class="font-weight-bolder mb-1">{{ users }}</h2>
                  <div>
                    <div
                      id="apexchartsoheokxio"
                      class="apexcharts-canvas apexchartsoheokxio apexcharts-theme-light"
                    >
                      <svg
                        id="SvgjsSvg2688"
                        width="100%"
                        height="80"
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xmlns:svgjs="http://svgjs.com/svgjs"
                        class="apexcharts-svg"
                        xmlns:data="ApexChartsNS"
                        transform="translate(0, 0)"
                        style="
                          background: transparent;
                          --darkreader-inline-bgimage: initial;
                          --darkreader-inline-bgcolor: transparent;
                        "
                        data-darkreader-inline-bgimage=""
                        data-darkreader-inline-bgcolor=""
                      >
                        <g
                          id="SvgjsG2690"
                          class="apexcharts-inner apexcharts-graphical"
                          transform="translate(0, 0)"
                        >
                          <defs id="SvgjsDefs2689">
                            <clipPath id="gridRectMask2oxev4no">
                              <rect
                                id="SvgjsRect2695"
                                width="100%"
                                height="82"
                                x="-3"
                                y="-1"
                                rx="0"
                                ry="0"
                                opacity="1"
                                stroke-width="0"
                                stroke="none"
                                stroke-dasharray="0"
                                fill="#fff"
                                data-darkreader-inline-fill=""
                                data-darkreader-inline-stroke=""
                                style="
                                  --darkreader-inline-fill: #131314;
                                  --darkreader-inline-stroke: none;
                                "
                              ></rect>
                            </clipPath>
                            <clipPath id="gridRectMarkerMask2oxev4no">
                              <rect
                                id="SvgjsRect2696"
                                width="100%"
                                height="84"
                                x="-2"
                                y="-2"
                                rx="0"
                                ry="0"
                                opacity="1"
                                stroke-width="0"
                                stroke="none"
                                stroke-dasharray="0"
                                fill="#fff"
                                data-darkreader-inline-fill=""
                                data-darkreader-inline-stroke=""
                                style="
                                  --darkreader-inline-fill: #131314;
                                  --darkreader-inline-stroke: none;
                                "
                              ></rect>
                            </clipPath>
                          </defs>
                          <line
                            id="SvgjsLine2694"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="80"
                            stroke="#b6b6b6"
                            stroke-dasharray="3"
                            class="apexcharts-xcrosshairs"
                            x="0"
                            y="0"
                            width="1"
                            height="80"
                            fill="#b1b9c4"
                            filter="none"
                            fill-opacity="0.9"
                            stroke-width="1"
                            data-darkreader-inline-fill=""
                            data-darkreader-inline-stroke=""
                            style="
                              --darkreader-inline-fill: #2d3035;
                              --darkreader-inline-stroke: #353738;
                            "
                          ></line>
                          <g
                            id="SvgjsG2706"
                            class="apexcharts-xaxis"
                            transform="translate(0, 0)"
                          >
                            <g
                              id="SvgjsG2707"
                              class="apexcharts-xaxis-texts-g"
                              transform="translate(0, -4)"
                            ></g>
                          </g>
                          <g id="SvgjsG2720" class="apexcharts-grid">
                            <g
                              id="SvgjsG2721"
                              class="apexcharts-gridlines-horizontal"
                              style="display: none"
                            >
                              <line
                                id="SvgjsLine2723"
                                x1="0"
                                y1="0"
                                x2="265.547"
                                y2="0"
                                stroke="#e0e0e0"
                                stroke-dasharray="0"
                                class="apexcharts-gridline"
                                data-darkreader-inline-stroke=""
                                style="--darkreader-inline-stroke: #2c2e2f"
                              ></line>
                              <line
                                id="SvgjsLine2724"
                                x1="0"
                                y1="20"
                                x2="265.547"
                                y2="20"
                                stroke="#e0e0e0"
                                stroke-dasharray="0"
                                class="apexcharts-gridline"
                                data-darkreader-inline-stroke=""
                                style="--darkreader-inline-stroke: #2c2e2f"
                              ></line>
                              <line
                                id="SvgjsLine2725"
                                x1="0"
                                y1="40"
                                x2="265.547"
                                y2="40"
                                stroke="#e0e0e0"
                                stroke-dasharray="0"
                                class="apexcharts-gridline"
                                data-darkreader-inline-stroke=""
                                style="--darkreader-inline-stroke: #2c2e2f"
                              ></line>
                              <line
                                id="SvgjsLine2726"
                                x1="0"
                                y1="60"
                                x2="265.547"
                                y2="60"
                                stroke="#e0e0e0"
                                stroke-dasharray="0"
                                class="apexcharts-gridline"
                                data-darkreader-inline-stroke=""
                                style="--darkreader-inline-stroke: #2c2e2f"
                              ></line>
                              <line
                                id="SvgjsLine2727"
                                x1="0"
                                y1="80"
                                x2="265.547"
                                y2="80"
                                stroke="#e0e0e0"
                                stroke-dasharray="0"
                                class="apexcharts-gridline"
                                data-darkreader-inline-stroke=""
                                style="--darkreader-inline-stroke: #2c2e2f"
                              ></line>
                            </g>
                            <g
                              id="SvgjsG2722"
                              class="apexcharts-gridlines-vertical"
                              style="display: none"
                            ></g>
                            <line
                              id="SvgjsLine2729"
                              x1="0"
                              y1="80"
                              x2="265.547"
                              y2="80"
                              stroke="transparent"
                              stroke-dasharray="0"
                              data-darkreader-inline-stroke=""
                              style="--darkreader-inline-stroke: transparent"
                            ></line>
                            <line
                              id="SvgjsLine2728"
                              x1="0"
                              y1="1"
                              x2="0"
                              y2="80"
                              stroke="transparent"
                              stroke-dasharray="0"
                              data-darkreader-inline-stroke=""
                              style="--darkreader-inline-stroke: transparent"
                            ></line>
                          </g>
                          <g
                            id="SvgjsG2697"
                            class="apexcharts-line-series apexcharts-plot-series"
                          >
                            <g
                              id="SvgjsG2698"
                              class="apexcharts-series"
                              seriesName="seriesx1"
                              data:longestSeries="true"
                              rel="1"
                              data:realIndex="0"
                            >
                              <path
                                id="SvgjsPath2701"
                                d="M 0 19C 9.294145 19 17.260555 32 26.5547 32C 35.848845 32 43.815255 11 53.1094 11C 62.403545 11 70.369955 28 79.6641 28C 88.958245 28 96.924655 20 106.2188 20C 115.512945 20 123.47935500000001 40 132.7735 40C 142.067645 40 150.03405500000002 1 159.3282 1C 168.622345 1 176.58875500000002 20 185.8829 20C 195.177045 20 203.14345500000002 21 212.4376 21C 221.731745 21 229.698155 37 238.9923 37C 248.28644500000001 37 256.252855 18 265.547 18"
                                fill="none"
                                fill-opacity="1"
                                stroke="rgba(125,167,65,1)"
                                stroke-opacity="1"
                                stroke-linecap="butt"
                                stroke-width="2"
                                stroke-dasharray="0"
                                class="apexcharts-line"
                                index="0"
                                clip-path="url(#gridRectMask2oxev4no)"
                                pathTo="M 0 19C 9.294145 19 17.260555 32 26.5547 32C 35.848845 32 43.815255 11 53.1094 11C 62.403545 11 70.369955 28 79.6641 28C 88.958245 28 96.924655 20 106.2188 20C 115.512945 20 123.47935500000001 40 132.7735 40C 142.067645 40 150.03405500000002 1 159.3282 1C 168.622345 1 176.58875500000002 20 185.8829 20C 195.177045 20 203.14345500000002 21 212.4376 21C 221.731745 21 229.698155 37 238.9923 37C 248.28644500000001 37 256.252855 18 265.547 18"
                                pathFrom="M -1 80L -1 80L 26.5547 80L 53.1094 80L 79.6641 80L 106.2188 80L 132.7735 80L 159.3282 80L 185.8829 80L 212.4376 80L 238.9923 80L 265.547 80"
                                data-darkreader-inline-stroke=""
                                style="
                                  --darkreader-inline-stroke: rgba(
                                    86,
                                    81,
                                    138,
                                    0.85
                                  );
                                "
                              ></path>
                              <g
                                id="SvgjsG2699"
                                class="apexcharts-series-markers-wrap"
                                data:realIndex="0"
                              ></g>
                            </g>
                            <g
                              id="SvgjsG2702"
                              class="apexcharts-series"
                              seriesName="seriesx2"
                              data:longestSeries="true"
                              rel="2"
                              data:realIndex="1"
                            >
                              <path
                                id="SvgjsPath2705"
                                d="M 0 60C 9.294145 60 17.260555 70 26.5547 70C 35.848845 70 43.815255 50 53.1094 50C 62.403545 50 70.369955 65 79.6641 65C 88.958245 65 96.924655 57 106.2188 57C 115.512945 57 123.47935500000001 80 132.7735 80C 142.067645 80 150.03405500000002 55 159.3282 55C 168.622345 55 176.58875500000002 65 185.8829 65C 195.177045 65 203.14345500000002 60 212.4376 60C 221.731745 60 229.698155 75 238.9923 75C 248.28644500000001 75 256.252855 53 265.547 53"
                                fill="none"
                                fill-opacity="1"
                                stroke="rgba(220,218,227,0.85)"
                                stroke-opacity="1"
                                stroke-linecap="butt"
                                stroke-width="1"
                                stroke-dasharray="5"
                                class="apexcharts-line"
                                index="1"
                                clip-path="url(#gridRectMask2oxev4no)"
                                pathTo="M 0 60C 9.294145 60 17.260555 70 26.5547 70C 35.848845 70 43.815255 50 53.1094 50C 62.403545 50 70.369955 65 79.6641 65C 88.958245 65 96.924655 57 106.2188 57C 115.512945 57 123.47935500000001 80 132.7735 80C 142.067645 80 150.03405500000002 55 159.3282 55C 168.622345 55 176.58875500000002 65 185.8829 65C 195.177045 65 203.14345500000002 60 212.4376 60C 221.731745 60 229.698155 75 238.9923 75C 248.28644500000001 75 256.252855 53 265.547 53"
                                pathFrom="M -1 80L -1 80L 26.5547 80L 53.1094 80L 79.6641 80L 106.2188 80L 132.7735 80L 159.3282 80L 185.8829 80L 212.4376 80L 238.9923 80L 265.547 80"
                                data-darkreader-inline-stroke=""
                                style="
                                  --darkreader-inline-stroke: rgba(
                                    157,
                                    156,
                                    154,
                                    0.85
                                  );
                                "
                              ></path>
                              <g
                                id="SvgjsG2703"
                                class="apexcharts-series-markers-wrap"
                                data:realIndex="1"
                              ></g>
                            </g>
                            <g
                              id="SvgjsG2700"
                              class="apexcharts-datalabels"
                              data:realIndex="0"
                            ></g>
                            <g
                              id="SvgjsG2704"
                              class="apexcharts-datalabels"
                              data:realIndex="1"
                            ></g>
                          </g>
                          <line
                            id="SvgjsLine2730"
                            x1="0"
                            y1="0"
                            x2="265.547"
                            y2="0"
                            stroke="#b6b6b6"
                            stroke-dasharray="0"
                            stroke-width="1"
                            class="apexcharts-ycrosshairs"
                            data-darkreader-inline-stroke=""
                            style="--darkreader-inline-stroke: #353738"
                          ></line>
                          <line
                            id="SvgjsLine2731"
                            x1="0"
                            y1="0"
                            x2="265.547"
                            y2="0"
                            stroke-dasharray="0"
                            stroke-width="0"
                            class="apexcharts-ycrosshairs-hidden"
                          ></line>
                          <g
                            id="SvgjsG2732"
                            class="apexcharts-yaxis-annotations"
                          ></g>
                          <g
                            id="SvgjsG2733"
                            class="apexcharts-xaxis-annotations"
                          ></g>
                          <g
                            id="SvgjsG2734"
                            class="apexcharts-point-annotations"
                          ></g>
                        </g>
                        <rect
                          id="SvgjsRect2693"
                          width="0"
                          height="0"
                          x="0"
                          y="0"
                          rx="0"
                          ry="0"
                          opacity="1"
                          stroke-width="0"
                          stroke="none"
                          stroke-dasharray="0"
                          fill="#fefefe"
                          data-darkreader-inline-fill=""
                          data-darkreader-inline-stroke=""
                          style="
                            --darkreader-inline-fill: #adacab;
                            --darkreader-inline-stroke: none;
                          "
                        ></rect>
                        <g
                          id="SvgjsG2719"
                          class="apexcharts-yaxis"
                          rel="0"
                          transform="translate(-18, 0)"
                        ></g>
                        <g id="SvgjsG2691" class="apexcharts-annotations"></g>
                      </svg>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
          <div class="col-xl-3 col-md-3 col-sm-6 col-3">
            <div class="card-header px-0">
              <div class="col-12 align-items-end d-flex">
                <h4 class="d-flex">Enquiry (Today)</h4>
              </div>
            </div>
            <div class="card card-congratulation-medal">
              <router-link to="/enquiry">
                <div class="card-body pb-50" style="position: relative">
                  <h2 class="font-weight-bolder mb-1">{{ today_enquiry }}</h2>
                  <div>
                    <div
                      id="apexchartsoheokxio"
                      class="apexcharts-canvas apexchartsoheokxio apexcharts-theme-light"
                    >
                      <svg
                        id="SvgjsSvg2688"
                        width="100%"
                        height="80"
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xmlns:svgjs="http://svgjs.com/svgjs"
                        class="apexcharts-svg"
                        xmlns:data="ApexChartsNS"
                        transform="translate(0, 0)"
                        style="
                          background: transparent;
                          --darkreader-inline-bgimage: initial;
                          --darkreader-inline-bgcolor: transparent;
                        "
                        data-darkreader-inline-bgimage=""
                        data-darkreader-inline-bgcolor=""
                      >
                        <g
                          id="SvgjsG2690"
                          class="apexcharts-inner apexcharts-graphical"
                          transform="translate(0, 0)"
                        >
                          <defs id="SvgjsDefs2689">
                            <clipPath id="gridRectMask2oxev4no">
                              <rect
                                id="SvgjsRect2695"
                                width="100%"
                                height="82"
                                x="-3"
                                y="-1"
                                rx="0"
                                ry="0"
                                opacity="1"
                                stroke-width="0"
                                stroke="none"
                                stroke-dasharray="0"
                                fill="#fff"
                                data-darkreader-inline-fill=""
                                data-darkreader-inline-stroke=""
                                style="
                                  --darkreader-inline-fill: #131314;
                                  --darkreader-inline-stroke: none;
                                "
                              ></rect>
                            </clipPath>
                            <clipPath id="gridRectMarkerMask2oxev4no">
                              <rect
                                id="SvgjsRect2696"
                                width="100%"
                                height="84"
                                x="-2"
                                y="-2"
                                rx="0"
                                ry="0"
                                opacity="1"
                                stroke-width="0"
                                stroke="none"
                                stroke-dasharray="0"
                                fill="#fff"
                                data-darkreader-inline-fill=""
                                data-darkreader-inline-stroke=""
                                style="
                                  --darkreader-inline-fill: #131314;
                                  --darkreader-inline-stroke: none;
                                "
                              ></rect>
                            </clipPath>
                          </defs>
                          <line
                            id="SvgjsLine2694"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="80"
                            stroke="#b6b6b6"
                            stroke-dasharray="3"
                            class="apexcharts-xcrosshairs"
                            x="0"
                            y="0"
                            width="1"
                            height="80"
                            fill="#b1b9c4"
                            filter="none"
                            fill-opacity="0.9"
                            stroke-width="1"
                            data-darkreader-inline-fill=""
                            data-darkreader-inline-stroke=""
                            style="
                              --darkreader-inline-fill: #2d3035;
                              --darkreader-inline-stroke: #353738;
                            "
                          ></line>
                          <g
                            id="SvgjsG2706"
                            class="apexcharts-xaxis"
                            transform="translate(0, 0)"
                          >
                            <g
                              id="SvgjsG2707"
                              class="apexcharts-xaxis-texts-g"
                              transform="translate(0, -4)"
                            ></g>
                          </g>
                          <g id="SvgjsG2720" class="apexcharts-grid">
                            <g
                              id="SvgjsG2721"
                              class="apexcharts-gridlines-horizontal"
                              style="display: none"
                            >
                              <line
                                id="SvgjsLine2723"
                                x1="0"
                                y1="0"
                                x2="265.547"
                                y2="0"
                                stroke="#e0e0e0"
                                stroke-dasharray="0"
                                class="apexcharts-gridline"
                                data-darkreader-inline-stroke=""
                                style="--darkreader-inline-stroke: #2c2e2f"
                              ></line>
                              <line
                                id="SvgjsLine2724"
                                x1="0"
                                y1="20"
                                x2="265.547"
                                y2="20"
                                stroke="#e0e0e0"
                                stroke-dasharray="0"
                                class="apexcharts-gridline"
                                data-darkreader-inline-stroke=""
                                style="--darkreader-inline-stroke: #2c2e2f"
                              ></line>
                              <line
                                id="SvgjsLine2725"
                                x1="0"
                                y1="40"
                                x2="265.547"
                                y2="40"
                                stroke="#e0e0e0"
                                stroke-dasharray="0"
                                class="apexcharts-gridline"
                                data-darkreader-inline-stroke=""
                                style="--darkreader-inline-stroke: #2c2e2f"
                              ></line>
                              <line
                                id="SvgjsLine2726"
                                x1="0"
                                y1="60"
                                x2="265.547"
                                y2="60"
                                stroke="#e0e0e0"
                                stroke-dasharray="0"
                                class="apexcharts-gridline"
                                data-darkreader-inline-stroke=""
                                style="--darkreader-inline-stroke: #2c2e2f"
                              ></line>
                              <line
                                id="SvgjsLine2727"
                                x1="0"
                                y1="80"
                                x2="265.547"
                                y2="80"
                                stroke="#e0e0e0"
                                stroke-dasharray="0"
                                class="apexcharts-gridline"
                                data-darkreader-inline-stroke=""
                                style="--darkreader-inline-stroke: #2c2e2f"
                              ></line>
                            </g>
                            <g
                              id="SvgjsG2722"
                              class="apexcharts-gridlines-vertical"
                              style="display: none"
                            ></g>
                            <line
                              id="SvgjsLine2729"
                              x1="0"
                              y1="80"
                              x2="265.547"
                              y2="80"
                              stroke="transparent"
                              stroke-dasharray="0"
                              data-darkreader-inline-stroke=""
                              style="--darkreader-inline-stroke: transparent"
                            ></line>
                            <line
                              id="SvgjsLine2728"
                              x1="0"
                              y1="1"
                              x2="0"
                              y2="80"
                              stroke="transparent"
                              stroke-dasharray="0"
                              data-darkreader-inline-stroke=""
                              style="--darkreader-inline-stroke: transparent"
                            ></line>
                          </g>
                          <g
                            id="SvgjsG2697"
                            class="apexcharts-line-series apexcharts-plot-series"
                          >
                            <g
                              id="SvgjsG2698"
                              class="apexcharts-series"
                              seriesName="seriesx1"
                              data:longestSeries="true"
                              rel="1"
                              data:realIndex="0"
                            >
                              <path
                                id="SvgjsPath2701"
                                d="M 0 19C 9.294145 19 17.260555 32 26.5547 32C 35.848845 32 43.815255 11 53.1094 11C 62.403545 11 70.369955 28 79.6641 28C 88.958245 28 96.924655 20 106.2188 20C 115.512945 20 123.47935500000001 40 132.7735 40C 142.067645 40 150.03405500000002 1 159.3282 1C 168.622345 1 176.58875500000002 20 185.8829 20C 195.177045 20 203.14345500000002 21 212.4376 21C 221.731745 21 229.698155 37 238.9923 37C 248.28644500000001 37 256.252855 18 265.547 18"
                                fill="none"
                                fill-opacity="1"
                                stroke="rgba(125,167,65,1)"
                                stroke-opacity="1"
                                stroke-linecap="butt"
                                stroke-width="2"
                                stroke-dasharray="0"
                                class="apexcharts-line"
                                index="0"
                                clip-path="url(#gridRectMask2oxev4no)"
                                pathTo="M 0 19C 9.294145 19 17.260555 32 26.5547 32C 35.848845 32 43.815255 11 53.1094 11C 62.403545 11 70.369955 28 79.6641 28C 88.958245 28 96.924655 20 106.2188 20C 115.512945 20 123.47935500000001 40 132.7735 40C 142.067645 40 150.03405500000002 1 159.3282 1C 168.622345 1 176.58875500000002 20 185.8829 20C 195.177045 20 203.14345500000002 21 212.4376 21C 221.731745 21 229.698155 37 238.9923 37C 248.28644500000001 37 256.252855 18 265.547 18"
                                pathFrom="M -1 80L -1 80L 26.5547 80L 53.1094 80L 79.6641 80L 106.2188 80L 132.7735 80L 159.3282 80L 185.8829 80L 212.4376 80L 238.9923 80L 265.547 80"
                                data-darkreader-inline-stroke=""
                                style="
                                  --darkreader-inline-stroke: rgba(
                                    86,
                                    81,
                                    138,
                                    0.85
                                  );
                                "
                              ></path>
                              <g
                                id="SvgjsG2699"
                                class="apexcharts-series-markers-wrap"
                                data:realIndex="0"
                              ></g>
                            </g>
                            <g
                              id="SvgjsG2702"
                              class="apexcharts-series"
                              seriesName="seriesx2"
                              data:longestSeries="true"
                              rel="2"
                              data:realIndex="1"
                            >
                              <path
                                id="SvgjsPath2705"
                                d="M 0 60C 9.294145 60 17.260555 70 26.5547 70C 35.848845 70 43.815255 50 53.1094 50C 62.403545 50 70.369955 65 79.6641 65C 88.958245 65 96.924655 57 106.2188 57C 115.512945 57 123.47935500000001 80 132.7735 80C 142.067645 80 150.03405500000002 55 159.3282 55C 168.622345 55 176.58875500000002 65 185.8829 65C 195.177045 65 203.14345500000002 60 212.4376 60C 221.731745 60 229.698155 75 238.9923 75C 248.28644500000001 75 256.252855 53 265.547 53"
                                fill="none"
                                fill-opacity="1"
                                stroke="rgba(220,218,227,0.85)"
                                stroke-opacity="1"
                                stroke-linecap="butt"
                                stroke-width="1"
                                stroke-dasharray="5"
                                class="apexcharts-line"
                                index="1"
                                clip-path="url(#gridRectMask2oxev4no)"
                                pathTo="M 0 60C 9.294145 60 17.260555 70 26.5547 70C 35.848845 70 43.815255 50 53.1094 50C 62.403545 50 70.369955 65 79.6641 65C 88.958245 65 96.924655 57 106.2188 57C 115.512945 57 123.47935500000001 80 132.7735 80C 142.067645 80 150.03405500000002 55 159.3282 55C 168.622345 55 176.58875500000002 65 185.8829 65C 195.177045 65 203.14345500000002 60 212.4376 60C 221.731745 60 229.698155 75 238.9923 75C 248.28644500000001 75 256.252855 53 265.547 53"
                                pathFrom="M -1 80L -1 80L 26.5547 80L 53.1094 80L 79.6641 80L 106.2188 80L 132.7735 80L 159.3282 80L 185.8829 80L 212.4376 80L 238.9923 80L 265.547 80"
                                data-darkreader-inline-stroke=""
                                style="
                                  --darkreader-inline-stroke: rgba(
                                    157,
                                    156,
                                    154,
                                    0.85
                                  );
                                "
                              ></path>
                              <g
                                id="SvgjsG2703"
                                class="apexcharts-series-markers-wrap"
                                data:realIndex="1"
                              ></g>
                            </g>
                            <g
                              id="SvgjsG2700"
                              class="apexcharts-datalabels"
                              data:realIndex="0"
                            ></g>
                            <g
                              id="SvgjsG2704"
                              class="apexcharts-datalabels"
                              data:realIndex="1"
                            ></g>
                          </g>
                          <line
                            id="SvgjsLine2730"
                            x1="0"
                            y1="0"
                            x2="265.547"
                            y2="0"
                            stroke="#b6b6b6"
                            stroke-dasharray="0"
                            stroke-width="1"
                            class="apexcharts-ycrosshairs"
                            data-darkreader-inline-stroke=""
                            style="--darkreader-inline-stroke: #353738"
                          ></line>
                          <line
                            id="SvgjsLine2731"
                            x1="0"
                            y1="0"
                            x2="265.547"
                            y2="0"
                            stroke-dasharray="0"
                            stroke-width="0"
                            class="apexcharts-ycrosshairs-hidden"
                          ></line>
                          <g
                            id="SvgjsG2732"
                            class="apexcharts-yaxis-annotations"
                          ></g>
                          <g
                            id="SvgjsG2733"
                            class="apexcharts-xaxis-annotations"
                          ></g>
                          <g
                            id="SvgjsG2734"
                            class="apexcharts-point-annotations"
                          ></g>
                        </g>
                        <rect
                          id="SvgjsRect2693"
                          width="0"
                          height="0"
                          x="0"
                          y="0"
                          rx="0"
                          ry="0"
                          opacity="1"
                          stroke-width="0"
                          stroke="none"
                          stroke-dasharray="0"
                          fill="#fefefe"
                          data-darkreader-inline-fill=""
                          data-darkreader-inline-stroke=""
                          style="
                            --darkreader-inline-fill: #adacab;
                            --darkreader-inline-stroke: none;
                          "
                        ></rect>
                        <g
                          id="SvgjsG2719"
                          class="apexcharts-yaxis"
                          rel="0"
                          transform="translate(-18, 0)"
                        ></g>
                        <g id="SvgjsG2691" class="apexcharts-annotations"></g>
                      </svg>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
          <div class="col-xl-3 col-md-3 col-sm-6 col-3">
            <div class="card-header px-0">
              <div class="col-12 align-items-end d-flex">
                <h4 class="d-flex">Revenue</h4>
              </div>
            </div>
            <div class="card card-congratulation-medal">
              <div class="card-body pb-50" style="position: relative">
                <h2 class="font-weight-bolder mb-1">
                  ₹{{
                    parseFloat(revenue)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }}.00
                </h2>
                <div>
                  <div
                    id="apexchartsoheokxio"
                    class="apexcharts-canvas apexchartsoheokxio apexcharts-theme-light"
                  >
                    <svg
                      id="SvgjsSvg2688"
                      width="100%"
                      height="80"
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      xmlns:svgjs="http://svgjs.com/svgjs"
                      class="apexcharts-svg"
                      xmlns:data="ApexChartsNS"
                      transform="translate(0, 0)"
                      style="
                        background: transparent;
                        --darkreader-inline-bgimage: initial;
                        --darkreader-inline-bgcolor: transparent;
                      "
                      data-darkreader-inline-bgimage=""
                      data-darkreader-inline-bgcolor=""
                    >
                      <g
                        id="SvgjsG2690"
                        class="apexcharts-inner apexcharts-graphical"
                        transform="translate(0, 0)"
                      >
                        <defs id="SvgjsDefs2689">
                          <clipPath id="gridRectMask2oxev4no">
                            <rect
                              id="SvgjsRect2695"
                              width="100%"
                              height="82"
                              x="-3"
                              y="-1"
                              rx="0"
                              ry="0"
                              opacity="1"
                              stroke-width="0"
                              stroke="none"
                              stroke-dasharray="0"
                              fill="#fff"
                              data-darkreader-inline-fill=""
                              data-darkreader-inline-stroke=""
                              style="
                                --darkreader-inline-fill: #131314;
                                --darkreader-inline-stroke: none;
                              "
                            ></rect>
                          </clipPath>
                          <clipPath id="gridRectMarkerMask2oxev4no">
                            <rect
                              id="SvgjsRect2696"
                              width="100%"
                              height="84"
                              x="-2"
                              y="-2"
                              rx="0"
                              ry="0"
                              opacity="1"
                              stroke-width="0"
                              stroke="none"
                              stroke-dasharray="0"
                              fill="#fff"
                              data-darkreader-inline-fill=""
                              data-darkreader-inline-stroke=""
                              style="
                                --darkreader-inline-fill: #131314;
                                --darkreader-inline-stroke: none;
                              "
                            ></rect>
                          </clipPath>
                        </defs>
                        <line
                          id="SvgjsLine2694"
                          x1="0"
                          y1="0"
                          x2="0"
                          y2="80"
                          stroke="#b6b6b6"
                          stroke-dasharray="3"
                          class="apexcharts-xcrosshairs"
                          x="0"
                          y="0"
                          width="1"
                          height="80"
                          fill="#b1b9c4"
                          filter="none"
                          fill-opacity="0.9"
                          stroke-width="1"
                          data-darkreader-inline-fill=""
                          data-darkreader-inline-stroke=""
                          style="
                            --darkreader-inline-fill: #2d3035;
                            --darkreader-inline-stroke: #353738;
                          "
                        ></line>
                        <g
                          id="SvgjsG2706"
                          class="apexcharts-xaxis"
                          transform="translate(0, 0)"
                        >
                          <g
                            id="SvgjsG2707"
                            class="apexcharts-xaxis-texts-g"
                            transform="translate(0, -4)"
                          ></g>
                        </g>
                        <g id="SvgjsG2720" class="apexcharts-grid">
                          <g
                            id="SvgjsG2721"
                            class="apexcharts-gridlines-horizontal"
                            style="display: none"
                          >
                            <line
                              id="SvgjsLine2723"
                              x1="0"
                              y1="0"
                              x2="265.547"
                              y2="0"
                              stroke="#e0e0e0"
                              stroke-dasharray="0"
                              class="apexcharts-gridline"
                              data-darkreader-inline-stroke=""
                              style="--darkreader-inline-stroke: #2c2e2f"
                            ></line>
                            <line
                              id="SvgjsLine2724"
                              x1="0"
                              y1="20"
                              x2="265.547"
                              y2="20"
                              stroke="#e0e0e0"
                              stroke-dasharray="0"
                              class="apexcharts-gridline"
                              data-darkreader-inline-stroke=""
                              style="--darkreader-inline-stroke: #2c2e2f"
                            ></line>
                            <line
                              id="SvgjsLine2725"
                              x1="0"
                              y1="40"
                              x2="265.547"
                              y2="40"
                              stroke="#e0e0e0"
                              stroke-dasharray="0"
                              class="apexcharts-gridline"
                              data-darkreader-inline-stroke=""
                              style="--darkreader-inline-stroke: #2c2e2f"
                            ></line>
                            <line
                              id="SvgjsLine2726"
                              x1="0"
                              y1="60"
                              x2="265.547"
                              y2="60"
                              stroke="#e0e0e0"
                              stroke-dasharray="0"
                              class="apexcharts-gridline"
                              data-darkreader-inline-stroke=""
                              style="--darkreader-inline-stroke: #2c2e2f"
                            ></line>
                            <line
                              id="SvgjsLine2727"
                              x1="0"
                              y1="80"
                              x2="265.547"
                              y2="80"
                              stroke="#e0e0e0"
                              stroke-dasharray="0"
                              class="apexcharts-gridline"
                              data-darkreader-inline-stroke=""
                              style="--darkreader-inline-stroke: #2c2e2f"
                            ></line>
                          </g>
                          <g
                            id="SvgjsG2722"
                            class="apexcharts-gridlines-vertical"
                            style="display: none"
                          ></g>
                          <line
                            id="SvgjsLine2729"
                            x1="0"
                            y1="80"
                            x2="265.547"
                            y2="80"
                            stroke="transparent"
                            stroke-dasharray="0"
                            data-darkreader-inline-stroke=""
                            style="--darkreader-inline-stroke: transparent"
                          ></line>
                          <line
                            id="SvgjsLine2728"
                            x1="0"
                            y1="1"
                            x2="0"
                            y2="80"
                            stroke="transparent"
                            stroke-dasharray="0"
                            data-darkreader-inline-stroke=""
                            style="--darkreader-inline-stroke: transparent"
                          ></line>
                        </g>
                        <g
                          id="SvgjsG2697"
                          class="apexcharts-line-series apexcharts-plot-series"
                        >
                          <g
                            id="SvgjsG2698"
                            class="apexcharts-series"
                            seriesName="seriesx1"
                            data:longestSeries="true"
                            rel="1"
                            data:realIndex="0"
                          >
                            <path
                              id="SvgjsPath2701"
                              d="M 0 19C 9.294145 19 17.260555 32 26.5547 32C 35.848845 32 43.815255 11 53.1094 11C 62.403545 11 70.369955 28 79.6641 28C 88.958245 28 96.924655 20 106.2188 20C 115.512945 20 123.47935500000001 40 132.7735 40C 142.067645 40 150.03405500000002 1 159.3282 1C 168.622345 1 176.58875500000002 20 185.8829 20C 195.177045 20 203.14345500000002 21 212.4376 21C 221.731745 21 229.698155 37 238.9923 37C 248.28644500000001 37 256.252855 18 265.547 18"
                              fill="none"
                              fill-opacity="1"
                              stroke="rgba(125,167,65,1)"
                              stroke-opacity="1"
                              stroke-linecap="butt"
                              stroke-width="2"
                              stroke-dasharray="0"
                              class="apexcharts-line"
                              index="0"
                              clip-path="url(#gridRectMask2oxev4no)"
                              pathTo="M 0 19C 9.294145 19 17.260555 32 26.5547 32C 35.848845 32 43.815255 11 53.1094 11C 62.403545 11 70.369955 28 79.6641 28C 88.958245 28 96.924655 20 106.2188 20C 115.512945 20 123.47935500000001 40 132.7735 40C 142.067645 40 150.03405500000002 1 159.3282 1C 168.622345 1 176.58875500000002 20 185.8829 20C 195.177045 20 203.14345500000002 21 212.4376 21C 221.731745 21 229.698155 37 238.9923 37C 248.28644500000001 37 256.252855 18 265.547 18"
                              pathFrom="M -1 80L -1 80L 26.5547 80L 53.1094 80L 79.6641 80L 106.2188 80L 132.7735 80L 159.3282 80L 185.8829 80L 212.4376 80L 238.9923 80L 265.547 80"
                              data-darkreader-inline-stroke=""
                              style="
                                --darkreader-inline-stroke: rgba(
                                  86,
                                  81,
                                  138,
                                  0.85
                                );
                              "
                            ></path>
                            <g
                              id="SvgjsG2699"
                              class="apexcharts-series-markers-wrap"
                              data:realIndex="0"
                            ></g>
                          </g>
                          <g
                            id="SvgjsG2702"
                            class="apexcharts-series"
                            seriesName="seriesx2"
                            data:longestSeries="true"
                            rel="2"
                            data:realIndex="1"
                          >
                            <path
                              id="SvgjsPath2705"
                              d="M 0 60C 9.294145 60 17.260555 70 26.5547 70C 35.848845 70 43.815255 50 53.1094 50C 62.403545 50 70.369955 65 79.6641 65C 88.958245 65 96.924655 57 106.2188 57C 115.512945 57 123.47935500000001 80 132.7735 80C 142.067645 80 150.03405500000002 55 159.3282 55C 168.622345 55 176.58875500000002 65 185.8829 65C 195.177045 65 203.14345500000002 60 212.4376 60C 221.731745 60 229.698155 75 238.9923 75C 248.28644500000001 75 256.252855 53 265.547 53"
                              fill="none"
                              fill-opacity="1"
                              stroke="rgba(220,218,227,0.85)"
                              stroke-opacity="1"
                              stroke-linecap="butt"
                              stroke-width="1"
                              stroke-dasharray="5"
                              class="apexcharts-line"
                              index="1"
                              clip-path="url(#gridRectMask2oxev4no)"
                              pathTo="M 0 60C 9.294145 60 17.260555 70 26.5547 70C 35.848845 70 43.815255 50 53.1094 50C 62.403545 50 70.369955 65 79.6641 65C 88.958245 65 96.924655 57 106.2188 57C 115.512945 57 123.47935500000001 80 132.7735 80C 142.067645 80 150.03405500000002 55 159.3282 55C 168.622345 55 176.58875500000002 65 185.8829 65C 195.177045 65 203.14345500000002 60 212.4376 60C 221.731745 60 229.698155 75 238.9923 75C 248.28644500000001 75 256.252855 53 265.547 53"
                              pathFrom="M -1 80L -1 80L 26.5547 80L 53.1094 80L 79.6641 80L 106.2188 80L 132.7735 80L 159.3282 80L 185.8829 80L 212.4376 80L 238.9923 80L 265.547 80"
                              data-darkreader-inline-stroke=""
                              style="
                                --darkreader-inline-stroke: rgba(
                                  157,
                                  156,
                                  154,
                                  0.85
                                );
                              "
                            ></path>
                            <g
                              id="SvgjsG2703"
                              class="apexcharts-series-markers-wrap"
                              data:realIndex="1"
                            ></g>
                          </g>
                          <g
                            id="SvgjsG2700"
                            class="apexcharts-datalabels"
                            data:realIndex="0"
                          ></g>
                          <g
                            id="SvgjsG2704"
                            class="apexcharts-datalabels"
                            data:realIndex="1"
                          ></g>
                        </g>
                        <line
                          id="SvgjsLine2730"
                          x1="0"
                          y1="0"
                          x2="265.547"
                          y2="0"
                          stroke="#b6b6b6"
                          stroke-dasharray="0"
                          stroke-width="1"
                          class="apexcharts-ycrosshairs"
                          data-darkreader-inline-stroke=""
                          style="--darkreader-inline-stroke: #353738"
                        ></line>
                        <line
                          id="SvgjsLine2731"
                          x1="0"
                          y1="0"
                          x2="265.547"
                          y2="0"
                          stroke-dasharray="0"
                          stroke-width="0"
                          class="apexcharts-ycrosshairs-hidden"
                        ></line>
                        <g
                          id="SvgjsG2732"
                          class="apexcharts-yaxis-annotations"
                        ></g>
                        <g
                          id="SvgjsG2733"
                          class="apexcharts-xaxis-annotations"
                        ></g>
                        <g
                          id="SvgjsG2734"
                          class="apexcharts-point-annotations"
                        ></g>
                      </g>
                      <rect
                        id="SvgjsRect2693"
                        width="0"
                        height="0"
                        x="0"
                        y="0"
                        rx="0"
                        ry="0"
                        opacity="1"
                        stroke-width="0"
                        stroke="none"
                        stroke-dasharray="0"
                        fill="#fefefe"
                        data-darkreader-inline-fill=""
                        data-darkreader-inline-stroke=""
                        style="
                          --darkreader-inline-fill: #adacab;
                          --darkreader-inline-stroke: none;
                        "
                      ></rect>
                      <g
                        id="SvgjsG2719"
                        class="apexcharts-yaxis"
                        rel="0"
                        transform="translate(-18, 0)"
                      ></g>
                      <g id="SvgjsG2691" class="apexcharts-annotations"></g>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row match-height">
          <div class="col-xl-3 col-md-3 col-sm-6 col-3" v-if="chartShow">
            <div class="card-header px-0">
              <div class="col-12 align-items-end d-flex">
                <h4 class="d-flex">Booking By Payment Methods</h4>
              </div>
            </div>
            <div class="card card-congratulation-medal">
              <router-link to="/booking">
                <div class="card-body pb-50" style="position: relative">
                  <ChartComponent :chartType="chartType" :chartData="chartData" :chartOptions="chartOptions" />
                </div>
              </router-link>
            </div>
          </div>
          <div class="col-xl-9 col-md-9 col-sm-6 col-9" v-if="paymentMonthlyChart.chartShow">
            <div class="card-header px-0">
              <div class="col-12 align-items-end d-flex justify-content-between">
                <h4 class="d-flex">Annual Booking Revenue</h4>
                <div class="year-btn">
                  <button class="btn btn-sm btn-primary" @click="getPaymentMonthlyChart(year)" :class="{'active': year == paymentMonthlyChart.selectedYear}" v-for="year in paymentMonthlyChart.years" :key="year">{{ year }}</button>
                </div>
              </div>
            </div>
            <div class="card card-congratulation-medal paymentMonthlyChart-container">
                <div class="card-body pb-50" style="position: relative">
                  <ChartComponent v-if="paymentMonthlyChart.loader" style="height: 290px;width:100%;" :chartType="paymentMonthlyChart.chartType" :chartData="paymentMonthlyChart.chartData" :chartOptions="paymentMonthlyChart.chartOptions" />
                </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import ChartComponent from '../components/ChartComponent.vue';

export default {
  name: "Dashboard",
  components: {
    ChartComponent
  },
  data() {
    return {
      today: new Date(),
      response: "",
      list: [],
      from_date: "",
      to_date: "",
      token: localStorage.getItem("auth_token"),
      id: null,
      vill_type_id: null,
      showDate: null,
      loader: false,
      villaLoader: false,
      villaListLoader: false,
      villaList: [],
      isVillaList: false,
      selectedVillaList: [],
      isClick: false,
      activeLi: [],
      villaId: null,
      modalDismis: false,
      temp_villa: {
        id: null,
        no: null,
      },
      today_picnic_header: [
        "Booking Id",
        "Check in",
        "Check out",
        "Package Name",
        "Name",
        "Mobile",
        "",
      ],
      today_picnic_list: [],
      today_bookings: 0,
      users: 0,
      revenue: 0,
      today_enquiry: 0,
      loader_picnic: false,
      chartShow: false,
      chartType: "doughnut",
      chartData: {
        labels: ['Online', 'Cash'], // Label for the single value
        datasets: [
          {
            backgroundColor: ['rgba(22, 158, 190, 0.4)', 'rgba(125, 167, 66, 0.4)'],
            borderColor: ['rgba(22, 158, 190, 1)', 'rgba(125, 167, 66, 1)'],
            borderWidth: 1,
            data: [] // The single value along with a complement value to fill the chart
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        cutoutPercentage: 80 // Customize the cutout percentage for the Doughnut chart
      },
      paymentMonthlyChart: {
        selectedYear: new Date().getFullYear(),
        years: [2022, 2023, 2024],
        chartShow: false,
        loader: false,
        chartType: "bar",
        chartData: {
          labels: [],
          datasets: [
            {
              label: 'Online',
              backgroundColor: 'rgba(22, 158, 190, 0.4)',
              borderColor: 'rgba(22, 158, 190, 1)',
              borderWidth: 1,
              data: []
            },
            {
              label: 'Cash',
              backgroundColor: 'rgba(125, 167, 66, 0.4)',
              borderColor: 'rgba(125, 167, 66, 1)',
              borderWidth: 1,
              data: []
            }
          ]
        },
        chartOptions: {
          responsive: true,
          maintainAspectRatio: false,
        },
      }
    };
  },
  async mounted() {
    this.from_date = moment(this.today).startOf("week").format("YYYY-MM-DD");
    var t = new Date(this.from_date);
    this.to_date = moment(t.setDate(t.getDate() + 6)).format("YYYY-MM-DD");
    this.showDate =
      moment(this.from_date).format("MMM DD, YYYY") +
      " - " +
      moment(this.to_date).format("MMM DD, YYYY");


    const currentYear = moment().year();
    this.paymentMonthlyChart.years = this.generateYearRange(currentYear - 2, currentYear)

    this.getResults();
    this.allVilla();
    this.incomingPicnic();
    this.getPaymentMonthlyChart(currentYear);

  },
  methods: {
    generateYearRange(startYear, endYear) {
      const years = [];
      for (let year = startYear; year <= endYear; year++) {
        years.push(year);
      }
      return years;
    },
    async getResults() {
      this.loader = true;
      await axios
        .post(
          process.env.VUE_APP_API_URL + "/dashboard",
          {
            from_date: this.from_date ? this.from_date : "2022-12-26",
            to_date: this.to_date ? this.to_date : "2023-01-01",
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${this.token}`,
            },
          }
        )
        .then((response) => {
          // console.log("response: ", response);
          this.loader = false;
          this.list = response.data.data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$router.go("/login");
            localStorage.removeItem("auth_token");
            localStorage.removeItem("auth_name");
          }
        });
    },
    async allVilla() {
      await axios
        .get(process.env.VUE_APP_API_URL + "/dashboard/villa-type-master", {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${this.token}`,
          },
        })
        .then((response) => {
          this.selectedVillaList = response.data.data;
        });
    },
    async incomingPicnic() {
      this.loader_picnic = true;
      await axios
        .get(
          process.env.VUE_APP_API_URL +
            "/booking/incoming-bookings?type=OneDayPicnic",
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${this.token}`,
            },
          }
        )
        .then((response) => {
          // console.log("response: ", response);
          this.loader_picnic = false;
          this.today_picnic_list = response.data.data.bookings;
          this.today_bookings = response.data.data.today_bookings;
          this.users = response.data.data.users;
          this.revenue = response.data.data.revenue;
          this.today_enquiry = response.data.data.today_enquiry;
          this.chartData.datasets[0].data[0] = response.data.data.revenue_by_methods.online;
          this.chartData.datasets[0].data[1] = response.data.data.revenue_by_methods.cash;
          this.chartShow = true;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$router.go("/login");
            localStorage.removeItem("auth_token");
            localStorage.removeItem("auth_name");
          }
        });
    },
    async getPaymentMonthlyChart(year) {
      this.paymentMonthlyChart.loader = false;
      this.paymentMonthlyChart.selectedYear = year;
      await axios
        .get(
          process.env.VUE_APP_API_URL +
            "/booking/payments/" + year,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${this.token}`,
            },
          }
        )
        .then((response) => {

          this.paymentMonthlyChart.chartData.labels = response.data.map(item => item.month);
          this.paymentMonthlyChart.chartData.datasets[0].data = response.data.map(item => item.online);
          this.paymentMonthlyChart.chartData.datasets[1].data = response.data.map(item => item.cash);
          this.paymentMonthlyChart.chartShow = true;
          this.paymentMonthlyChart.loader = true;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$router.go("/login");
            localStorage.removeItem("auth_token");
            localStorage.removeItem("auth_name");
          }
        });
    },
    dateName(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    dayName(date) {
      return moment(date).format("ddd");
    },
    async pre() {
      var f = new Date(this.from_date);
      this.to_date = moment(f.setDate(f.getDate() - 1)).format("YYYY-MM-DD");
      var t = new Date(this.to_date);
      this.from_date = moment(t.setDate(t.getDate() - 6)).format("YYYY-MM-DD");
      this.showDate =
        moment(this.from_date).format("MMM DD, YYYY") +
        " - " +
        moment(this.to_date).format("MMM DD, YYYY");
      this.getResults();
    },
    async next() {
      var t = new Date(this.to_date);
      this.from_date = moment(t.setDate(t.getDate() + 1)).format("YYYY-MM-DD");
      var f = new Date(this.from_date);
      this.to_date = moment(f.setDate(f.getDate() + 6)).format("YYYY-MM-DD");
      this.showDate =
        moment(this.from_date).format("MMM DD, YYYY") +
        " - " +
        moment(this.to_date).format("MMM DD, YYYY");
      this.getResults();
    },
    getStatus(item) {
      if (item.status == 1 || item.user.user_type == "Owner") {
        return ["Complete", "badge-light-success"];
      } else if (item.status == 2) {
        return ["Pending", "badge-light-warning"];
      } else if (item.status == 3) {
        return ["Cancel", "badge-light-danger"];
      } else if (item.status == 4) {
        return ["Failed", "badge-light-primary"];
      } else if (item.status == 0) {
        return ["Running", "badge-light-info"];
      }
    },
    villaStatus(status) {
      if (status == 1) {
        return `villa`;
      } else if (status == 3) {
        return `cancel_villa`;
      }
    },
    fullname(item) {
      var first_name = item.first_name;
      var middle_name = item.middle_name ? " " + item.middle_name : "";
      var last_name = item.last_name ? " " + item.last_name : "";

      return first_name + middle_name + last_name;
    },
    villaColor(villa) {
      var text = '';
      var label_background = '';
      if(villa == 'PLATINUM VILLA'){
        text = 'Platinum Villa';
        label_background = '#5AB2FF';
      }else if(villa == 'DIAMOND VILLA'){
        text = 'Diamond Villa';
        label_background = '#FA7070';
      }else if(villa == 'Cottages'){
        text = 'Cottages';
        label_background = '#7743DB';
      }
      return {
        text : text,
        label_background : label_background,
      }
    }
  },
};
</script>

<style scoped>
.week_button {
  display: flex;
  align-items: center;
}

.week {
  text-align: center;
  margin: 0 10px;
}

.main_row {
  width: 100%;
  display: flex;
}

.week_div {
  width: 14%;
  margin: 5px;
  /* border-radius: 20px; */
  text-align: center;
  font-weight: 600;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
}

.week-card {
  /* border-radius: 20px !important; */
  border: none !important;
  margin-bottom: 0 !important;
}

a {
  font-weight: 300 !important;
}

a:hover {
  font-weight: 300 !important;
  text-decoration: underline;
}

.week-card-header {
  background: linear-gradient(97deg, #7da741 62.6%, #dcf1be 135.36%) !important;
  /* background: linear-gradient(118deg, #7367f0, rgba(115, 103, 240, 0.7)) !important; */
  /* border-top-left-radius: 20px;
	border-top-right-radius: 20px; */
  justify-content: center;
  padding: 5px !important;
  color: #fff !important;
}

.week-card-body {
  padding: 0px !important;
}

.card-title-week {
  font-size: 1.1rem !important;
  margin: 10px 0 5px 0;
  background: #b3adf11f;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-top: 1px solid #7da741;
  /* border-top: 1px solid #7367f0; */
  padding: 5px;
}

.card-person-div {
  font-weight: normal;
  text-align: left;
  padding: 5px 10px;
}

.person_name {
  font-weight: bold;
}

.villa {
  box-shadow: 0px 6px 20px 5px rgb(185 182 218 / 21%);
}

.villa:hover {
  box-shadow: inset 4px -7px 20px 4px rgb(185 182 218 / 21%);
  cursor: pointer;
}

.nodata {
  padding: 25px !important;
}

.nodata span {
  font-weight: 100;
}

.pre:hover,
.next:hover {
  background-color: #fff;
  color: #7da741;
  /* color: #7367f0; */
  border-radius: 4px;
}

td {
  border-bottom: 1px solid #eeecf9;
}

.villa_list ul {
  padding-left: 5px;
  margin-bottom: 0;
}

.villa_list ul li {
  list-style: none;
  padding: 2px 10px;
  border-radius: 5px;
}

.Li:hover {
  background-color: #e3e0ff;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
}

.villa_list {
  position: absolute;
  padding: 5px 10px;
  height: auto;
  max-height: 200px;
  min-height: 80px;
  width: 45%;
  transition: height 4s;
  background-color: #fff;
  box-shadow: -1px 4px 13px 0px #9e9e9e8a !important;
  border-radius: 5px;
  right: unset !important;
  top: 25px;
  overflow-y: auto;
  z-index: 9999999;
  padding: 20px;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 600px;
    margin: 1.75rem auto;
  }
}

.modal-content {
  min-height: 600px;
  height: auto;
}

.close:hover {
  background-color: unset !important;
  color: #000;
  width: 17px;
  height: 22px;
  transition: all 0.5s;
  transform: unset !important;
}

.close {
  float: right;
  margin-top: -20px !important;
  margin-right: -15px !important;
  width: 15px;
  height: 21px;
  padding: 3px 3px 3px 4px;
  cursor: pointer;
  font-weight: 700;
  background-color: #fff;
  color: #000;
  box-shadow: none !important;
}

.activeLi {
  background-color: #999999;
  color: #000;
  cursor: not-allowed;
  margin: 2px auto;
}

.villaCancel:hover {
  cursor: pointer;
  font-weight: bold;
}

.success_villa {
  background-color: rgba(40, 199, 111, 0.12);
  pointer-events: none;
  cursor: default;
}

.cancel_villa {
  background-color: rgba(234, 84, 85, 0.12);
  pointer-events: none;
  cursor: default;
}

.card-title-week {
  font-size: 1rem !important;
  background: #7da741;
  border-top-left-radius: unset;
  border-top-right-radius: unset;
  color: #fff;
  text-transform: capitalize;
}

.text-title {
  text-transform: capitalize;
}
.villa:hover {
  box-shadow: 0px 6px 20px 5px rgb(185 182 218 / 21%);
  cursor: pointer;
}

a.btn {
  text-decoration: none;
}

a.btn:hover {
  text-decoration: none;
}

.btn-gradient-primary {
  background: #28a745 !important;
}

.checked {
  width: 17px;
  padding-top: 5px;
}
.checked.success {
  color: #28a745;
}

.checked.pending {
  color: #ffc107;
}

.remove_padding {
  padding: 0;
}
.member .list {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.member .heading {
  margin-bottom: 10px;
  display: block;
  font-size: 15px;
}

.member .list li {
  display: flex;
  align-items: center;
}

.member .list li .icon {
  margin-right: 15px;
}

.member .list li .icon .vue-feather {
  width: 15px;
}

.member .list li .text {
  font-size: 14px;
  color: #000000b5;
  text-transform: capitalize;
}
.detail_card_wrapper {
  margin: 10px;
}
.detail_card {
  width: 100%;
  text-align: left;
  background-color: #fff;
  padding: 15px 20px;
  box-shadow: 0px 0px 60px #d3e5e9;
  border-radius: 10px;
  height: -moz-fit-content;
  height: fit-content;
}

.heading_other {
  margin-bottom: 10px;
  display: block;
  font-size: 15px;
  padding-left: 0;
  color: #42aea3;
}

.detail_card.invert {
  background-color: transparent;
  box-shadow: none;
  border: 1px solid #42aea3;
  margin-bottom: 10px;
}

.detail_card.mb {
  margin-bottom: 20px;
}

.detail_card .check_in_out_box {
  display: flex;
}

.detail_card .check_in_out_box.check_in_out_box_bottom {
  padding-bottom: 0 !important;
}

.detail_card .check_in_out_box .check_in_box:nth-child(1) {
  border-right: 1px solid #e9e9e9;
  padding-right: 15px;
  /* margin-right: 15px; */
}

.detail_card .check_in_out_box .check_in_box:nth-child(2) {
  padding-left: 20px !important;
}

.detail_card .check_in_box {
  padding-bottom: 5px;
}

.detail_card .check_in_box {
  width: 50%;
}

.detail_card .check_in_title {
  font-size: 15px;
  font-weight: 500;
  padding-bottom: 7px;
}

.detail_card .check_in_text {
  font-size: 18px;
  font-weight: 500;
  color: #42aea3;
}

.list_card .list_header {
  padding: 0 9px;
  background: #7da741;
  color: #fff;
}
.villa_number_ul {
  display: flex;
  padding: 3px;
  flex-wrap: wrap;
  border: 1px solid rgb(204 204 204 / 38%);
}

.villa_number_ul li {
  list-style: none;
  background: #cccccc57;
  /* color: #fff; */
  padding: 5px;
  margin: 5px;
  border-bottom: 1px solid;
}

.data_not_avilable li {
  background: transparent !important;
  color: #ccc !important;
  font-size: 12px;
  border-bottom: unset !important;
}

.card-congratulation-medal a:hover {
  text-decoration: none;
}

.year-btn {
}

.year-btn .btn {
  margin-left: 10px;
}

.year-btn .btn.active {
  opacity: 0.6;
  cursor: not-allowed;
  pointer-events: none;
}

</style>
